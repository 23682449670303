@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
  overflow-x: hidden;
}

@media screen and (max-width: 600px) {
  html {
    font-size: 80%;
  }
}

.App {
  width: 100vw;
  overflow-x: hidden;
}

.container {
  width: 100%;
  height: 80vh;
  background: linear-gradient(23deg, #6b50ff 0%, #a145ff 100%);
  position: relative;
}

@media screen and (max-width: 600px) {
  .container {
    width: 100%;
    height: 100vh;
    background: linear-gradient(23deg, #6b50ff 0%, #a145ff 100%);
    position: relative;
  }
}

.header {
  width: 100%;
  height: 10vh;
}

@media screen and (max-width: 600px) {
  .header {
    width: 100%;
    height: 20vh;
  }
}

nav {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .margin-maker {
    display: none;
  }
}

img.logo {
  width: 8rem;
}

a.contact-button {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 1.55rem */
  letter-spacing: 0.01rem;
  border-radius: 2rem;
  background-color: transparent;
  text-decoration: none;
  padding: 0rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0);
}

a:hover {
}

@media screen and (max-width: 600px) {
  a.contact-button {
    display: none;
  }
}

.margin-maker {
  margin-left: 20px;
  margin-right: 10px;
  display: inline-block;
}

a.header-button {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-style: normal;
  display: inline-block;
  font-weight: 400;
  line-height: 155%; /* 1.55rem */
  letter-spacing: 0.01rem;
  border-radius: 2rem;
  border: 1.5px solid #fff;
  background-color: transparent;
  text-decoration: none;
  padding: 0.8rem 2rem;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

a.header-button:hover {
  transform: scale(1.2);
}

.main-content {
  width: 100%;
  height: 60vh;
}

@media screen and (max-width: 600px) {
  .main-content {
    width: 100%;
    height: 80vh;
  }
}

.main-content-inside {
  width: 50%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .main-content-inside {
    width: 80%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

h1 {
  color: #fff;
  text-align: center;

  /* Heading 01- 56 Pixel */
  font-family: Nunito;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 4.2rem */
  letter-spacing: -0.035rem;
}

p {
  color: #fff;
  text-align: center;

  /* Paragraph - 18 Pixel */
  font-family: Nunito;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 1.74375rem */
  letter-spacing: 0.01125rem;
  margin: 2rem 0 2rem 0;
}

a {
  border-radius: 2rem;
  background: var(--primary-color, #6200d2);
  text-decoration: none;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 0px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 1.55rem */
  letter-spacing: 0.01rem;
  padding: 0.8rem 2rem;
  transition: 0.2s all ease-in-out;
}

a:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.custom-shape-divider-bottom-1691913848 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

@media screen and (max-width: 700px) {
  .custom-shape-divider-bottom-1691913848 {
    display: none;
  }
}

.custom-shape-divider-bottom-1691913848 svg {
  position: relative;
  display: block;
  width: calc(273% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1691913848 .shape-fill {
  fill: #ffffff;
}

.bottom-div-laptop {
  width: 100%;
  color: white;
  height: 30vh;
  position: relative;
}

.laptop {
  top: 90%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  margin: auto;
  max-width: 40rem;
}

@media screen and (max-width: 900px) {
  .laptop {
    top: 80%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    margin: auto;
    max-width: 40rem;
  }
}

@media screen and (max-width: 700px) {
  .laptop {
    display: none;
    /* top: 90%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    margin: auto;
    width: 25rem; */
  }
}
.laptop .laptop__screen {
  position: relative;
  z-index: 1;
  padding: 3%;
  border-radius: 2rem;
  background: #ecf1f7;
  background-image: linear-gradient(to bottom, #333, #111);
  box-shadow: 0 0.1rem 0 #cfcfcf;
  border: 2px solid #ccc;
}
.laptop .laptop__screen img {
  display: block;
  max-width: 100%;
  height: auto;
  aspect-ratio: attr(width) / attr(height);
  background: #000;
}
.laptop .laptop__bottom {
  position: relative;
  z-index: 1;
  margin-right: -7%;
  margin-left: -7%;
  height: 0.7rem;
  background: #e9eff5;
  background-image: linear-gradient(
    to right,
    #d2dde9 0%,
    #f9fcff 15%,
    #e5ebf2 40%,
    #e5ebf2 60%,
    #f9fcff 85%,
    #d2dde9 100%
  );
}
.laptop .laptop__bottom::before {
  display: block;
  margin: 0 auto;
  width: 20%;
  height: 0.7rem;
  border-radius: 0 0 0.2rem 0.2rem;
  background: #f6f9fc;
  background-image: linear-gradient(
    to right,
    #c3cfdb 0%,
    #f6f9fc 10%,
    #f6f9fc 90%,
    #c3cfdb 100%
  );
  content: " ";
}
.laptop .laptop__under {
  position: absolute;
  top: 100%;
  left: 25%;
  display: block;
  width: 50%;
  height: 1.5rem;
  background: #e2e8f0;
  background-image: linear-gradient(to bottom, #e2e8f0, #bec7d1);
}
.laptop .laptop__under::after,
.laptop .laptop__under::before {
  position: absolute;
  top: 0%;
  right: 100%;
  bottom: 0;
  display: block;
  width: 50%;
  border-bottom-left-radius: 100%;
  background: inherit;
  content: " ";
}
.laptop .laptop__under::after {
  right: auto;
  left: 100%;
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 0;
}
.laptop .laptop__shadow {
  position: absolute;
  right: -10%;
  bottom: -2.5rem;
  left: -10%;
  z-index: 0;
  height: 2rem;
  background: radial-gradient(ellipse closest-side, #000, transparent);
  opacity: 0.5;
}

footer {
  position: absolute;
  width: 100%;
  height: 10vh;
  top: 130%;
}

.footer-inside {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.footer-inside h1 {
  color: #211f25;
  font-family: Nunito;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}

h1 span:hover {
  cursor: pointer;
  color: #6200d2;
}

.main-div-bottom {
  width: 100%;
  height: 10vh;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  br {
    display: none;
  }
}

@media screen and (min-width: 700px) {
  .main-div-bottom {
    display: none;
  }
}
.main-div-bottom h1 {
  color: #ffffff;
  font-family: Nunito;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

a.margin-maker:hover {
  transform: scale(1.05);
}
a.header-button:hover {
  transform: scale(1.05);
}
